import React from 'react';
import styled from 'styled-components';
import {useI18next, Trans, Helmet} from 'gatsby-plugin-react-i18next';
import {styles, media, links, colors} from 'global.config';
import TrustedBy from 'components/TrustedBy';
import Button from 'components/Button';
import Stars from 'components/Stars';
import TopSectionImage from 'images/email-marketing-affiliate-program-money-spread.svg';
import ConfusedAstronaut from 'images/email-marketing-affiliate-program-confused-astronaut.svg';
import SignUp from 'images/email-marketing-affiliate-program-sign-up.svg';
import Sharing from 'images/email-marketing-affiliate-program-sharing.svg';
import Earning from 'images/email-marketing-affiliate-program-earning.svg';

const IndexSection = styled.section`
  text-align: center;
  color: #fff;
  position: relative;
`;

const IndexSectionContainer = styled.div`
  padding: 135px ${styles.containerPadding} 80px;
  position: relative;
  min-height: calc(100vh - 170px);
  margin: auto;
  max-width: ${styles.containerWidth};
  @media ${media.mobile} {
    padding-top: 150px;
  }
`;

const IndexSectionImage = styled.img`
  display: block;
  margin: 0 auto;
  @media ${media.mobile} {
    width: 235px;
    margin-bottom: 20px;
  }
`;

const IndexSectionSummary = styled.p`
  font-size: 16px;
  line-height: 20px;
  max-width: initial;
  margin: 30px auto;
  font-weight: 300;
`;

const HowMadeImage = styled.img`
  margin-bottom: -11px;
  margin-top: 34px;
`;

const HowMadeSection = styled.section`
  text-align: center;
  padding-bottom: 100px;
  max-width: 1100px;
  margin: 0 auto;
  p {
    margin: 0;
  }
`;

const BoxesContainer = styled.div`
  &::after {
    display: block;
    content: '';
    clear: both;
  }
`;
const BoxHeading = styled.p`
  font-size: 29px;
  font-weight: 500;
  line-height: 1.07;
  color: ${colors.text};
  @media ${media.tabletMobile} {
    font-size: 29px;
    font-weight: 500;
    line-height: 1.07;
  }
`;

const BoxText = styled.p`
  margin: 0 0 3px;
`;

const Box = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 2.07;
  margin-bottom: 53px;
  color: ${colors.text};
  float: left;
  width: 33.3%;
  @media ${media.tabletMobile} {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.4;
  }
`;

const BoxImage = styled.img`
  margin-bottom: 3px;
`;

const HowMadeHeading = styled.p`
  font-size: 55px;
  font-weight: 500;
  line-height: 1.09;
  color: ${colors.text};
  @media ${media.tabletMobile} {
    font-size: 40px;
    font-weight: 500;
    line-height: 1.23;
  }
`;
const HowMadeShortText = styled.p`
  font-size: 16px;
  line-height: 1.25;
  color: ${colors.textLight};
  padding-top: 24px;
  padding-bottom: 39px;
  @media ${media.tabletMobile} {
    font-size: 16px;
    line-height: 1.25;
    text-align: center;
  }
`;
const HowMadeHeadingMd = styled.p`
  font-size: 21px;
  font-weight: 500;
  line-height: 2.86;
  color: ${colors.text};
  padding-bottom: 24px;
  @media ${media.tabletMobile} {
    font-size: 21px;
    font-weight: 500;
    line-height: 1.43;
  }
`;

const Page: React.FC<{}> = ({}) => {
  const {t} = useI18next();
  return (
    <>
      <Helmet title={t('Email Marketing Affiliate Program')}>
        <meta
          name="description"
          content={t(
            'Make extra money by joining the MoonMail Email Marketing Affiliate Program. Receive 25% commision from everyone you refer. What are you waiting?'
          )}
        />
      </Helmet>
      <div>
        <Stars>
          <IndexSection>
            <IndexSectionContainer>
              <IndexSectionImage
                src={TopSectionImage}
                alt={t('Email marketing Affiliate Program')}
                title={t('Email marketing Affiliate Program')}
                width={303}
                height={201}
              />
              <h1>{t('Earn Money to Infinity and Beyond!')}</h1>
              <h2>
                {t(
                  '93% of customers are more likely to purchase when you connect with them on multiple channels'
                )}
              </h2>
              <IndexSectionSummary>
                <Trans>
                  Instead of working your ass off for a nickle and a dime commission, how would
                  $200, <br /> $500 or even $1,000+ look in your pocket every month?
                </Trans>
              </IndexSectionSummary>
              <p>
                <Button
                  href={links.signUp}
                  title={t(
                    'Start making money with the MoonMail Email marketing Affiliate Program!"'
                  )}>
                  {t('Start making money!')}
                </Button>
              </p>
            </IndexSectionContainer>
          </IndexSection>
          <TrustedBy />
        </Stars>
        <HowMadeSection>
          <HowMadeImage
            src={ConfusedAstronaut}
            alt={t('Join our Email marketing Affiliate Program')}
            title={t('Join our Email marketing Affiliate Program')}
            width="84"
            height="101"
          />
          <HowMadeHeading>
            <Trans>How can you make $500?</Trans>
          </HowMadeHeading>
          <HowMadeShortText>
            <Trans>
              Find a client who sends 2 campaigns a month with 500,000 emails <br />
              per campaign and you'll earn $500+ <b>EVERY</b> month.
            </Trans>
          </HowMadeShortText>
          <HowMadeHeadingMd>
            <Trans>Joining the MoonMail Affiliate Program is pretty simple:</Trans>
          </HowMadeHeadingMd>
          <BoxesContainer>
            <Box>
              <BoxImage
                src={SignUp}
                alt={t('Sign Up to our Email marketing Affiliate Program')}
                title={t('Sign Up to our Email marketing Affiliate Program')}
                width="84"
                height="101"
              />
              <BoxText>
                <Trans>Go ahead and</Trans>
              </BoxText>
              <BoxHeading>
                <Trans>
                  Sign up to
                  <br />
                  MoonMail
                </Trans>
              </BoxHeading>
            </Box>
            <Box>
              <BoxImage
                src={Sharing}
                alt={t("Share MoonMail's Email marketing Affiliate Program")}
                title={t("Share MoonMail's Email marketing Affiliate Program")}
                width="84"
                height="101"
              />
              <BoxText>
                <Trans>Get your unique link from your</Trans>
              </BoxText>
              <BoxHeading>
                <Trans>
                  Profile/Affiliates tab <br />
                  and Start Sharing It
                </Trans>
              </BoxHeading>
            </Box>
            <Box>
              <BoxImage
                src={Earning}
                alt={t('Make money with our Email marketing Affiliate Program')}
                title="Make money with our Email marketing Affiliate Program"
                width="145"
                height="101"
              />
              <BoxText>
                <Trans>Get a 25% recurring commission from</Trans>
              </BoxText>
              <BoxHeading>
                <Trans>
                  <strong>Whatever</strong> your Referrals <br />
                  Spend in MoonMail
                </Trans>
              </BoxHeading>
            </Box>
          </BoxesContainer>
          <Button href={links.signUp} title={t('Become a MoonMail Affiliate')}>
            {t('Become a MoonMail Affiliate')}
          </Button>
        </HowMadeSection>
      </div>
    </>
  );
};

export default Page;
